body {
  /* SurveyJS Creator V2 */
  --primary: #00ace0;
  /* --primary: #1ab7fa; */
  /* --primary-light: rgba(26, 183, 250, 0.1); */

  /* --foreground: #ededed;
  --primary-foreground: #ffffff; */
  --secondary: #1ab7fa;
  /* --background: #555555; */
  --background-dim: #fff;
  /* --background-dim: #4d4d4d; */
  /* --background-dim-light: #4d4d4d;  */
}
